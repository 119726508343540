app.videopreview = (function (app) {
    "use strict";

    var previewUrl = '/Common/video/teaser_sd.mp4';

    var element,
        video,
        pausePreview,
        registered,
        closing,
        onclose,
        onclosed;

    registered = false;
    closing = false;

    var register = function (elementId, callback) {
        element = document.getElementById(elementId);
        element.classList.remove('-hide');

        appendVideo(element);

        pausePreview = element.querySelectorAll('.pausePreview');

        if (pausePreview.length) {
            for (var i = 0; i < pausePreview.length; i++) {
                pausePreview[i].addEventListener('click', watchVideo);
            }
        }

        registered = true;

        document.addEventListener('scroll', close);
        element.addEventListener('wheel', close);
        element.addEventListener('click', close);
        element.addEventListener('transitionend', transitionend, false);


        var buttons = element.querySelectorAll('.button');

        //for (var i = 0; i < buttons.length; i++) {
        //    buttons[i].addEventListener('click', function (evt) {
        //        evt.stopPropagation();
        //    })
        //}

        if (typeof (callback) === 'function') callback.call(element);
        
        if (callback.oncreate && typeof (callback.oncreate) === 'function') callback.oncreate.call(element);
        if (callback.onclose && typeof (callback.onclose) === 'function') onclose = callback.onclose;
        if (callback.onclosed && typeof (callback.onclosed) === 'function') onclosed = callback.onclosed;

        return element;

    }

    var destroy = function (elementId,callback) {
        element = document.getElementById(elementId);
        if (!element) return;
        element.parentNode.removeChild(element);

        if (typeof (callback) === 'function') callback();

    }

    var appendVideo = function (element) {
        if (!element) return;
        video = document.createElement('video');
        var source = document.createElement('source');
        video.setAttribute('autoplay', 'autoplay');
        video.setAttribute('loop', 'loop');
        source.setAttribute('src', previewUrl);
        source.setAttribute('type', 'video/mp4');
        video.appendChild(source);
        element.appendChild(video);

        video.addEventListener('ended', close);
    }

    var close = function (evt) {
        if (!evt.target.classList.contains('pausePreview') && !evt.target.parentNode.classList.contains('pausePreview')) {
            evt.preventDefault();
        }
        evt.stopPropagation();
        if (closing) return;
        closing = true;
        document.getElementsByTagName('body')[0].scrollTop = 0;
        element.classList.add('-close');
        app.header.show();
        if (typeof (onclose) === 'function') onclose(element);
    }

    var transitionend = function (evt) {
        if (evt.target != element) return;
        if (evt.propertyName != 'height') return;
        document.getElementsByTagName('body')[0].scrollTop = 0;
        document.removeEventListener('scroll', close);
        if (typeof (onclosed) === 'function') onclosed(element);
        destroy(element.getAttribute('id'));
    }

    var watchVideo = function (evt) {
        evt.stopPropagation();
        close(evt);
        video.stop();
    }

    return {
        destroy: destroy,
        isRegistered : function() { return registered },
        register: register,
        url: function () {
            return previewUrl;
        }
    }
})(app)