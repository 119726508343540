var app = {
    top: null,
    page : null,
    winHeight: null,
    thresh: null,
    direction : 'down',
    updateWindowInfo: function (evt) {
        app.winHeight = window.innerHeight;
        app.thresh = app.hero.bottom() - app.header.height();
    },
    scrollDirection: function (evt) {
        var scroll = window.scrollY ? window.scrollY : document.documentElement.scrollTop;
        if (scroll > app.top) {
            app.top = scroll;
            app.direction = 'down';
        } else {
            app.top = scroll;
            app.direction = 'up';
        }
    },
    hintScroll: function (evt) {
        var hintScroll = document.getElementsByClassName('-hintscroll')[0];
        if (!hintScroll) return;

        setTimeout(function () {
            hintScroll.classList.add('-done');
            document.removeEventListener('scroll', app.hintScroll);
        }, 3000 )

    },
    ready: function (evt) {
        app.page = document.getElementsByTagName('body')[0].clientHeight ? document.getElementsByTagName('body')[0] : document.documentElement;

        if (/iPad|iPhone|iPod/g.test(navigator.userAgent) && !window.MSStream) {
            document.getElementsByTagName('html')[0].classList.add('iOS');
            var drop = document.querySelectorAll('.dropdown');

            for (var i = 0; i < drop.length; i++) {
                var d = drop[i];
                d.addEventListener('touchstart', function (evt) {
                    var target = evt.target;
                    while (!target.classList.contains('dropdown')) {
                        target = target.parentNode;
                    }
                    var ds = document.querySelectorAll('.dropdown');
                    for (var i = 0; i < ds.length; i++) {
                        ds[i].classList.remove('-active');
                    }
                    target.classList.add('-active');
                    evt.stopPropagation();
                })
            }

            document.addEventListener('touchstart', function (evt) {
                var ds = document.querySelectorAll('.dropdown');
                for (var i = 0; i < ds.length; i++) {
                    ds[i].classList.remove('-active');
                }
            })

            var links = document.querySelectorAll('a[data-fast-click]');

            for (var i = 0; i < links.length; i++) {
                links[i].addEventListener('touchstart', function (evt) {
                    evt.preventDefault();
                    var target = evt.target;
                    while (target.nodeName != 'A') {
                        target = target.parentNode;
                    }
                    window.location = target.getAttribute('href');
                })
            }

        }

        if (app.page.classList.contains('homepage')) {

            Modernizr.on('videoautoplay', function (avail) {
                if (avail && app.hero.sectorvideo() && window.innerWidth > 768) {
                    app.hero.bindsectors();
                    document.addEventListener('scroll', app.hero.fadecontent);

                    app.videopreview.register('videopreview', {
                        oncreate : function(element) {
                            app.header.hide();
                            app.updateWindowInfo();
                        },
                        onclosed: function (element) {
                            app.header.show();
                            app.updateWindowInfo();
                            document.removeEventListener('scroll', app.header.show);
                            app.cookie.create('videopreviewplayed','true',0)
                        }
                    });
                } else {

                    if (app.hero.sectorvideo() && window.innerWidth > 1024) {
                        app.hero.bindsectors();
                        document.addEventListener('scroll', app.hero.fadecontent);

                        app.videopreview.register('videopreview', {
                            oncreate: function (element) {
                                app.header.hide();
                                app.updateWindowInfo();
                            },
                            onclosed: function (element) {
                                app.header.show();
                                app.updateWindowInfo();
                                document.removeEventListener('scroll', app.header.show);
                                app.cookie.create('videopreviewplayed', 'true', 0)
                            }
                        });

                    } else {
                        app.videopreview.destroy();
                        app.hero.novideo();

                        if (app.hero.sectorvideo()) {
                            app.hero.carousel.init();
                        }

                    }


                }

            });


        }




        app.header.register();
        app.hero.register();

        app.updateWindowInfo();

        document.addEventListener('scroll', app.scrollDirection);
        document.addEventListener('scroll', app.hintScroll);

        document.addEventListener('resize', app.updateWindowInfo);
        app.form.register('contactForm');

        // Toggle navigation menu
        var toggle = document.getElementById('navToggle');
        toggle.addEventListener('click', function (evt) {
            var header = document.querySelectorAll('header')[0];
            var menu = header.querySelectorAll('nav')[0];
            menu.classList.toggle('-visible');
            app.page.classList.toggle('-mobileNav');
            evt.target.classList.toggle('-open');
        });

        // Google map scrolling
        var googlemap = document.getElementById('googlemap');
        if (googlemap) {

            googlemap.parentElement.addEventListener('click', function (evt) {
                evt.stopPropagation();
                googlemap.classList.remove('-lock');
            });

            document.addEventListener('click', function (evt) {
                googlemap.classList.add('-lock');
            });

        }
 
    },
    load: function (evt) {

        if (document.getElementById('openVideoModal')) {
            app.video.load('videoPlayer');
        }

        if (app.page.scrollTop > app.thresh) {
            app.header.minimize();
        }

    }
};

document.addEventListener('DOMContentLoaded', app.ready);
window.addEventListener('load', app.load);



app.cookie = (function () {

    var create = function (name, value, days) {
        var expires;
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        }
        else {
            expires = "";
        }
        document.cookie = name + "=" + value + expires + "; path=/";
    }

    var read = function (name) {
        var cookie = false;
        if (!document.cookie.length) return cookie;
        var start = document.cookie.indexOf(name + '=');
        if (start == -1) return cookie;
        name.length + 1;
        end = document.cookie.indexOf(";", start);
        if (end == -1) end = document.cookie.length;
        cookie = unescape(document.cookie.substring(start, end));
        return cookie;
    }


    return {
        create: create,
        read: read
    }

})();