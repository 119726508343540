var Loader = (function () {

    var element = null;

    var load = function () {
        element = document.getElementById('loader');
        element.addEventListener('transitionend', destroy);
        setTimeout(function () {
            element.classList.add('-hide');
        }, 500);
    }

    var destroy = function (evt) {
        element.removeEventListener('transitionend', destroy)
        element.parentElement.removeChild(element);
    }

    return {
        load: load
    }
})();

window.addEventListener('load', Loader.load);
