app.hero = (function(app) {
    "use strict";

    var element = null;
    var sector = null;

    var videos = {
        beer: '/Common/video/beer.mp4',
        coffee: '/Common/video/coffee.mp4',
        softdrink: '/Common/video/softdrink.mp4',
        water: '/Common/video/water.mp4'
    }

    var register = function () {
        element = document.getElementsByClassName('hero')[0];
        if (!element) return;
        sector = element.querySelectorAll('.sector')[0];
    }

    var destroy = function () {
        document.removeEventListener('scroll', fadecontent);
        var videos = element.querySelectorAll('video');
        if (videos.length) {
            for (var i = 0; i < videos.length; i++) {
                video.parentNode.removeChild(videos[i])
            }
            var icons = sector.querySelectorAll('li');
            for (var i = 0; i < icons.length; i++) {
                icons[i].removeEventListener('mouseenter', entersector);
                icons[i].removeEventListener('mouseleave', leavesector);
            }
        }
    }

    var fadecontent = function (evt) {
        if (!element || !element.children.length) return;
        if (bottom() + element.getBoundingClientRect().top < 0) return;
        for (var i = 0; i < element.children.length; i++) {
            if (element.children[i].nodeName == 'VIDEO') continue;
            var vis = (element.clientHeight + element.getBoundingClientRect().top) / element.clientHeight;
            vis = Math.min(vis, 1);
            vis = Math.max(vis, 0);
            element.children[i].style.opacity = vis;
        }
    }

    var sectorvideo = function() {
        if (!sector) return false;
        return true;
    }

    var appendvideos = function () {
        for (var sector in videos) {
            var video = document.createElement('video');
            var src = document.createElement('source');
            src.setAttribute('src', videos[sector]);
            src.setAttribute('type', 'video/mp4');
            video.appendChild(src);
            video.classList.add(sector);
            video.setAttribute('loop','true');
            element.appendChild(video);
        }
    }

    var readsectors = function () {
        appendvideos();
        var icons = sector.querySelectorAll('li');
        for (var i = 0; i < icons.length; i++) {
            icons[i].addEventListener('mouseenter', entersector);
            icons[i].addEventListener('mouseleave', leavesector);
        }
    }

    var entersector = function (evt) {
        var currentsector = evt.target.getAttribute('data-sector');
        element.classList.add(currentsector);
        var video = element.querySelectorAll('video.' + currentsector)[0];
        if(video) video.play();
    }

    var leavesector = function (evt) {
        var evt = evt;
        setTimeout(function () {
            var leavingsector = evt.target.getAttribute('data-sector');
            element.classList.remove(leavingsector);
            var video = element.querySelectorAll('video.' + leavingsector)[0];
            if (video) video.pause();
        }, 150);
    }

    var novideo = function () {
        return element.classList.add('-novideo');
    }

    var carousel = {
        slides: null,
        pager: null,
        currentSlide : null,
        sectors: [],
        transitioning : false,
        init: function () {
            this.slides = element.querySelectorAll('.copy');
            this.pager = document.createElement('UL');
            this.pager.classList.add('pager');
            for (var i = 0; i < this.slides.length; i++) {
                var slide = this.slides[i];
                if (slide.getAttribute('data-slide')) {
                    this.sectors.push(slide.getAttribute('data-slide'));
                }
                var tick = document.createElement('LI');
                tick.setAttribute('data-slide-ref', slide.getAttribute('data-slide'));
                tick.addEventListener('click', this.pagerClick.bind(this));
                this.pager.appendChild(tick);
                slide.addEventListener('animationend', this.next.bind(this));
                slide.addEventListener('transitionend', function (evt) {
                    if (!evt.target.getAttribute('data-slide')) return;
                    if (evt.target.classList.contains('-out')) {
                        evt.target.classList.remove('-current');
                        evt.target.classList.remove('-out');
                    }
                    var out = document.querySelectorAll('-out');
                    for (var i = 0; i < out.length; i++) {
                        out[i].classList.remove('-current');
                        out[i].classList.remove('-out');
                    }
                })
            }
            element.appendChild(this.pager)
            element.addEventListener('touchstart', this.touchHandler.bind(this), false);
            element.addEventListener('touchmove', this.touchHandler.bind(this), false);
            element.addEventListener('touchend', this.touchHandler.bind(this), false);
            this.goto(this.sectors[0]);
        },
        pagerClick: function(evt) {
            var slide = evt.target.getAttribute('data-slide-ref');
            this.goto(slide);
        },
        goto: function (slide) {
            this.transitioning = true;
            for (var i = 0; i < this.sectors.length; i++) {
                element.classList.remove(this.sectors[i]);
            }
            var prev = element.querySelector('.-current');
            if(prev) prev.classList.add('-out');
            if (slide) {
                element.classList.add(slide);
                element.querySelector('.copy.' + slide).classList.add('-current');
                this.currentSlide = slide;
            } 
        },
        prev: function (evt) {
            if (evt.type === 'animationend' && evt.animationName !== 'slide') return;
            var target = evt.target;
            if (evt.type !== 'animationend') {
                while (!target.getAttribute('data-slide')) { target = target.parentNode; }
            }
            var currentIndex = this.currentSlide ? this.sectors.indexOf(this.currentSlide) : this.sectors.indexOf(target.getAttribute('data-slide'));
            var nextSlide = (currentIndex != 0) ? this.sectors[currentIndex - 1] : this.sectors[this.sectors.length - 1];
            this.goto(nextSlide);

        },
        next: function (evt) {
            if (evt.type === 'animationend' && evt.animationName !== 'slide') return;
            var target = evt.target;
            if (evt.type !== 'animationend') {
                while (!target.getAttribute('data-slide')) { target = target.parentNode; }
            }
            var currentIndex = this.currentSlide ? this.sectors.indexOf(this.currentSlide) : this.sectors.indexOf(target.getAttribute('data-slide'));
            var nextSlide = (currentIndex < this.sectors.length - 1) ? this.sectors[currentIndex + 1] : nextSlide = this.sectors[0];
            this.goto(nextSlide);
        },
        touches: {
            "touchstart": { "x": -1, "y": -1 },
            "touchmove": { "x": -1, "y": -1 },
            "touchend": false,
            "direction": "undetermined"
        },
        swipeThresh: 50,
        isHorizontal: function () {
            return (Math.abs(this.touches.touchstart.x - this.touches.touchmove.x) > Math.abs(this.touches.touchstart.y - this.touches.touchmove.y));
        },
        mayBeSwipe : function() {
            return Math.abs(this.touches.touchstart.x - this.touches.touchmove.x) > 10;
        },
        touchHandler: function (evt) {
            var touch;
            if (typeof evt !== 'undefined' && evt.target.nodeName !== 'A' && evt.target.nodeName !== 'I') {

                if (typeof evt.touches !== 'undefined') {
                    touch = evt.touches[0];
                    switch (evt.type) {
                        case 'touchstart':
                            this.touches[evt.type].x = touch.pageX;
                            this.touches[evt.type].y = touch.pageY;
                        case 'touchmove':
                            this.touches[evt.type].x = touch.pageX;
                            this.touches[evt.type].y = touch.pageY;
                            break;
                        case 'touchend':
                            if (this.touches.touchstart.x > -1 && this.touches.touchmove.x > -1) {
                                if (Math.abs(this.touches.touchstart.x - this.touches.touchmove.x) > this.swipeThresh) {
                                    evt.preventDefault();
                                    this.touches.direction = this.touches.touchstart.x < this.touches.touchmove.x ? "right" : "left";
                                    this.touches.touchstart = { "x": -1, "y": -1 };
                                    this.touches.touchmove = { "x": -1, "y": -1 };
                                    if (this.touches.direction === 'right') this.prev(evt);
                                    if (this.touches.direction === 'left') this.next(evt);
                                }
                                this.touches.touchstart = { "x": -1, "y": -1 };
                                this.touches.touchmove = { "x": -1, "y": -1 };
                            }
                        default:
                            break;
                    }
                }
            }
        }
    }

    var height = function () {
        if (element) return element.clientHeight;
        return 0;
    }

    var bottom = function () {
        if (element) return element.clientHeight + element.offsetTop;
        return 0;
    }

    return {
        bottom: bottom,
        height: height,
        bindsectors: readsectors,
        carousel: carousel,
        fadecontent: fadecontent,
        register: register,
        sectorvideo: sectorvideo,
        novideo: novideo
    }

})(app);