app.form = (function (app) {
    "use strict";

    var formId;

    var register = function (formId) {
        var form = document.getElementById(formId);
        if (!form) return;

        formId = formId;

        labelCheckboxes('checkboxLabel', '-checked');

        form.setAttribute('novalidate', 'novalidate');

        form.addEventListener('submit', validateForm, false);

        var cancel = document.getElementById('resetForm');

        cancel.addEventListener('click', resetForm);

        
    }

    var validateForm = function (evt) {
        
        var form = evt.target;
        for (var i = 0; i < form.length; i++) {
            var name = form[i].tagName;

            switch (name) {
                case 'INPUT':
                    if (form[i].getAttribute('required')) {
                        var type = form[i].getAttribute('type');

                        switch (type) {
                            case 'email':
                                if (!validateEmail(form[i])) evt.preventDefault();
                                break;
                            case 'tel':
                                if (!validatePhoneNumber(form[i])) evt.preventDefault();
                                break;
                            case 'checkbox':
                                if (!validateCheckbox(form[i])) evt.preventDefault();
                                break;
                            default:
                                if (!validateTextInput(form[i])) evt.preventDefault();
                                break;     
                        }
                    }
                    break;

                case 'TEXTAREA':
                    if (form[i].getAttribute('required')) if (!validateTextArea(form[i])) evt.preventDefault();
                    break;

                default:
                    break;
            }
        }

    }

    var validateTextArea = function (element) {
        if( !element.value || element.value == '' ) return failValidation(element);
        var minLength = parseInt(element.getAttribute('minlength'));
        var maxLength = parseInt(element.getAttribute('maxlength'));
        if (minLength && element.value.length < minLength) return failValidation(element);
        if (maxLength && element.value.length > maxLength) return failValidation(element);
        return true;
    }

    var validatePhoneNumber = function (element) {
        if (!element.value || element.value == '') return failValidation(element);
        if (!element.value.match(/^([0-9]){1,50}$/g)) return failValidation(element);
        return true;
    }

    var validateEmail = function (element) {
        if (!element.value || element.value == '') return failValidation(element);
        if (!element.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g)) return failValidation(element);
        return true;
    }

    var validateCheckbox = function (element) {
        if (!element.checked) return failValidation(element);
        return true;
    }

    var validateTextInput = function(element) {
        if (!element.value || element.value == '') return failValidation(element);
        return true;
    }

    // Add invalid class on validation fail
    var failValidation = function(element) {
        element.parentElement.classList.add('-invalid');
        element.addEventListener('focus', reset);
        return false;
    }

    // Remove invalid class on focus field
    var reset = function (evt) {
        evt.target.parentElement.classList.remove('-invalid');
    }

    var resetForm = function (evt) {
        var form = document.getElementById(form);
        var textfields = document.querySelectorAll('input:not([type="checkbox"]), textarea');

        if (textfields.length) {
            for (var i = 0; i < textfields.length; i++) {
                var field = textfields[i];
                field.value = '';
            }
        }

    }

    // Style label for chackbox
    var labelCheckboxes = function (labelClass, checkedClass) {
        var checkboxLabels = document.getElementsByClassName(labelClass);

        if (checkboxLabels.length) {
            for (var i = 0; i < checkboxLabels.length; i++) {
                var label = checkboxLabels[i];
                var forAttribute = label.getAttribute('for');
                var checkbox = document.getElementById(forAttribute);
                label.addEventListener('click', function (evt) {
                    var forAttribute = evt.target.getAttribute('for');
                    var checkbox = document.getElementById(forAttribute);
                    if (!checkbox.checked) {
                        evt.target.classList.add(checkedClass);
                    } else {
                        evt.target.classList.remove(checkedClass);
                    }
                })
                checkbox.classList.add('-styleOverride')
            }
        }
    }

    return {
        register: register
    }

})(app)