app.header = ( function(app) {
    
    "use strict";

    var element = null;
    var register = function() {
        element = document.getElementsByTagName('header')[0];
        document.addEventListener('scroll', minimize);
    }

    var destroy = document.removeEventListener('scroll', minimize);

    var minimize = function(evt) {
        if (!element) return;
        if (app.top > app.thresh) {
            element.classList.add('-minimize');
        } else {
            element.classList.remove('-minimize');
        }
    }

    var show = function () {
        // if (app.top < 10) return hide();
        return element.classList.remove('-up');
    }

    var hide = function () {
        return element.classList.add('-up');
    }

    var height = function () {
        return element.clientHeight;
    }

    return {
        destroy: destroy,
        height: height,
        hide: hide,
        minimize: minimize,
        register: register,
        show: show
    }
})(app)